import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, INLINES } from '@contentful/rich-text-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, getCSSVariable, Link, Text } from '@opendoor/bricks-next';
import { Button, Image } from '@opendoor/bricks/core';
import { ColorScheme } from '@opendoor/bricks/system/theme';
import { novo } from '@opendoor/bricks/theme';

import { ILpElementSimpleList } from 'declarations/contentful';

import Container from '../Container';
import { defaultEaseCss } from '../motion/MotionVariants';
import {
  BNColorThemes,
  getBNComponentThemeColors,
  IBackgroundColors,
} from '../styles/ComponentTheme';

interface IFooterProps {
  backgroundColor?: IBackgroundColors;
  footerImage?: string;
  footerImageTitle?: string;
  footerNav?: ILpElementSimpleList[];
  footerTitle?: string;
  googlePlaystoreLink?: string;
  appleStoreLink?: string;
  helpCenterLink?: string;
  extraLegalText?: Document | undefined;
  showCustomerTestimonialLegal?: boolean | undefined;
  showEligibilityLegal?: boolean | undefined;
  showPromotionLegal?: boolean | undefined;
  showThePdpCopyrightLegal?: boolean | undefined;
}

const OpendoorIconLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="30"
      viewBox="0 0 22 30"
      fill="none"
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <title>Opendoor Icon Logo</title>
      <g clipPath="url(#clip0_1_54)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2462 11.5378C15.2462 4.44667 14.0271 1.50727 10.6755 1.50727C7.71982 1.50727 6.22677 3.56782 6.22677 10.7197C6.22677 17.7806 7.3847 20.6898 10.7974 20.6898C13.814 20.6898 15.2462 18.6593 15.2462 11.5378ZM0.4375 11.3561C0.4375 3.38602 5.95262 0.234375 10.8584 0.234375C16.0992 0.234375 21.0353 3.35581 21.0353 10.8712C21.0353 18.9018 15.5203 21.9627 10.6145 21.9627C5.37372 21.9627 0.4375 18.6292 0.4375 11.3561ZM19.9597 28.3745C19.4596 27.7147 17.5333 25.1732 17.5333 25.1732C17.3526 24.9347 17.0429 24.7908 16.7105 24.7908H4.76234C4.42997 24.7908 4.12027 24.9347 3.93954 25.1732L1.51348 28.374C1.43651 28.4755 1.39567 28.5937 1.39567 28.7145V29.2031C1.39567 29.366 1.52843 29.498 1.69226 29.498H19.7806C19.9444 29.498 20.0772 29.366 20.0772 29.2031V28.7145C20.0772 28.5937 20.0367 28.4758 19.9597 28.3745Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_54">
          <rect
            width="20.7284"
            height="29.2637"
            fill="white"
            transform="translate(0.4375 0.234375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Footer = ({
  backgroundColor = BNColorThemes.Grey,
  footerImage,
  footerImageTitle,
  footerNav,
  footerTitle,
  googlePlaystoreLink,
  appleStoreLink,
  helpCenterLink,
  extraLegalText,
  showCustomerTestimonialLegal,
  showEligibilityLegal,
  showPromotionLegal,
  showThePdpCopyrightLegal,
}: IFooterProps) => {
  const theme = getBNComponentThemeColors(
    backgroundColor in BNColorThemes ? (backgroundColor as BNColorThemes) : BNColorThemes.Grey,
  );

  const buttonBg: ColorScheme = 'warmgrey950';
  const buttonColor: ColorScheme = 'white0';
  const buttonHoverBg: ColorScheme = 'warmgrey800';
  const buttonActiveBg: ColorScheme = 'warmgrey800';

  const ArchImage = styled(Image)({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '99em 99em 8em 8em',
  });

  const FooterLinkColumn = ({ text, url }: { text: string; url: string }) => {
    return (
      <Box tag="li">
        <Link
          analyticsName={`footer-link-column-${text}`}
          typography="$bodyMedium"
          href={url}
          hoverStyle={{ color: theme.contentPrimary }}
          color={theme.contentPrimary}
        >
          {text}
        </Link>
      </Box>
    );
  };

  const FooterForegroundBox = ({
    children,
    title,
  }: {
    children: React.ReactNode;
    title: string;
  }) => {
    return (
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
        padding="$5x"
        backgroundColor={theme.bgPrimaryLight}
        borderRadius={getCSSVariable('$6x', 'radius')}
      >
        <Text
          tag="p"
          flex={1}
          // @ts-expect-error minWidth required
          minWidth={'10ch'}
          typography="$labelSmall"
          color={theme.contentPrimary}
        >
          {title}
        </Text>
        {children}
      </Box>
    );
  };

  return (
    <Box
      tag="footer"
      backgroundColor={theme.bgPrimary}
      // @ts-expect-error TODO
      color={theme.contentPrimary}
      paddingVertical="$12x"
      minHeight={450}
    >
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
          $largerThanSM={{ alignItems: 'center', gap: '$20x' }}
          justifyContent={'space-between'}
          gap="$8x"
          position="relative"
        >
          <OpendoorIconLogo />

          <Text
            tag="h2"
            typography="$subheaderMedium"
            $largerThanSM={{ typography: '$headerLarge' }}
            // @ts-expect-error TODO
            maxWidth="10ch"
            color={theme.contentPrimary}
            width="100%"
          >
            {footerTitle}
          </Text>

          {footerImage && (
            <Box maxWidth={180} $largerThanSM={{ maxWidth: 325, height: 440 }} height={264}>
              <ArchImage src={footerImage} alt={footerImageTitle || ''} loading="lazy" />
            </Box>
          )}
        </Box>

        {/* Links section */}
        <Box
          display="flex"
          justifyContent="flex-end"
          $largerThanMD={{ marginLeft: 320 }}
          marginTop={80}
          marginBottom="$12x"
          flexDirection="column"
        >
          <Box
            display="flex"
            gap="$8x"
            flexWrap={'wrap'}
            $largerThanSM={{ flexWrap: 'nowrap' }}
            flexDirection="row"
            marginBottom="$16x"
          >
            {footerNav &&
              footerNav.map((navItem, i) => (
                <Box key={i} flex={1} flexBasis={'45%'}>
                  <Text
                    tag="h3"
                    typography="$subheaderSmall"
                    color={theme.contentPrimary}
                    marginBottom="$12x"
                  >
                    {navItem.fields.title}
                  </Text>
                  <Box tag="ul" padding={0} display="flex" flexDirection="column" gap="$8x">
                    {navItem &&
                      navItem.fields &&
                      navItem.fields.links &&
                      navItem.fields.links.map((link, i) => (
                        <FooterLinkColumn
                          key={i}
                          text={link.fields.text || ''}
                          url={link.fields.url || ''}
                        />
                      ))}
                  </Box>
                </Box>
              ))}
          </Box>
          <Box $largerThanSM={{ flexDirection: 'row' }} gap="$8x">
            <FooterForegroundBox title="Get the app">
              <Box
                display="flex"
                flex={1}
                flexWrap="wrap"
                justifyContent="flex-end"
                flexDirection="row"
                gap="$4x"
                css={css`
                  flex-direction: column;

                  @media (min-width: 440px) {
                    flex-direction: row;
                  }
                  @media (min-width: 768px) {
                    flex-direction: column;
                  }
                  @media (min-width: 874px) {
                    flex-direction: row;
                  }
                `}
              >
                <Link
                  href={appleStoreLink}
                  aria-label="Opendoor Applestore button"
                  hoverStyle={{ color: theme.contentPrimary }}
                  color={theme.contentPrimary}
                  analyticsName="cosmos-landing-page-v2-footer-applesotre-button"
                >
                  <Image
                    src="https://images.opendoor.com/source/s3/imgdrop-production/2023-12-1702502078078-95093.png?preset=square-2048"
                    loading="lazy"
                    alt="Opendoor Applestore"
                    width={135}
                    height={45}
                  />
                </Link>
                <Link
                  href={googlePlaystoreLink}
                  aria-label="Opendoor Google play button"
                  hoverStyle={{ color: theme.contentPrimary }}
                  color={theme.contentPrimary}
                  analyticsName="cosmos-landing-page-v2-footer-googlgplay-button"
                >
                  <Image
                    src="https://images.opendoor.com/source/s3/imgdrop-production/2023-12-1702502078081-28903.png?preset=square-2048"
                    loading="lazy"
                    alt="Opendoor Google Play"
                    width={135}
                    height={45}
                  />
                </Link>
              </Box>
            </FooterForegroundBox>

            <FooterForegroundBox title="Still have questions?">
              <Button
                as="a"
                type="submit"
                href={helpCenterLink}
                aria-label="Opendoor Help Center button"
                analyticsName="cosmos-landing-page-v2-footer-help-center"
                bg={buttonBg}
                color={buttonColor}
                alignItems="center"
                display="inline-flex"
                fontSize="14px"
                letterSpacing="-1.4"
                pb="8px"
                pt="8px"
                minHeight="45px"
                maxHeight="45px"
                textAlign="center"
                width="fit-content"
                position="relative"
                overflow="hidden"
                _after={{
                  aspectRatio: '1 / 1',
                  backgroundColor: buttonHoverBg,
                  content: '""',
                  display: 'block',
                  borderRadius: '99em 99em 10em 10em',
                  left: '0',
                  position: 'absolute',
                  top: '100%',
                  transform: 'scale(0.5)',
                  transition: `0.5s ${defaultEaseCss} transform`,
                  width: '100%',
                }}
                _hover={{
                  backgroundColor: buttonBg,
                  color: buttonColor,
                }}
                _focusVisible={{
                  backgroundColor: buttonHoverBg,
                }}
                _active={{
                  backgroundColor: buttonActiveBg,
                }}
                css={css`
                  border-radius: ${novo.radii.roundedSquare};
                  transition: 0.3s ${defaultEaseCss} background-color;
                  will-change: transform;

                  &:after {
                    will-change: transform;
                  }

                  @media (hover: hover) and (pointer: fine) {
                    &:hover:after {
                      transform: translate3d(0, -100%, 0) scale(2);
                    }
                  }
                `}
              >
                <Box tag="span" zIndex={5}>
                  Visit the Help Center
                </Box>
              </Button>
            </FooterForegroundBox>
          </Box>

          <Box
            backgroundColor={theme.bgPrimaryLight}
            borderRadius={getCSSVariable('$6x', 'radius')}
            marginTop="$8x"
            padding="$8x"
            // @ts-expect-error expect TODO
            color="$contentPrimary"
          >
            {extraLegalText && (
              <Box
                marginBottom="$12x"
                css={css`
                  * + * {
                    margin-top: 16px;
                  }
                `}
                // @ts-expect-error dynamic children inherit this color
                color={theme.contentPrimary}
              >
                {documentToReactComponents(extraLegalText, {
                  renderNode: {
                    [INLINES.HYPERLINK]: (node, children) => (
                      <Link
                        href={node.data.uri}
                        analyticsName="cosmos-footer"
                        aria-label=""
                        hoverStyle={{ color: theme.contentPrimary }}
                        color={theme.contentPrimary}
                        textDecorationLine="underline"
                      >
                        {children}
                      </Link>
                    ),
                  },
                })}
              </Box>
            )}
            <Text
              tag="p"
              typography="$bodyMedium"
              color={theme.contentPrimary}
              css={css`
                * + * {
                  margin-bottom: 16px;
                }
              `}
            >
              {showEligibilityLegal && <>{`Offer eligibility and purchase price vary.`} </>}
              {showCustomerTestimonialLegal && (
                <>
                  {`Featured customers may have been compensated for their time. Their opinions and views are solely their own.`}{' '}
                </>
              )}
              {showThePdpCopyrightLegal && (
                <>
                  {`Data is subject to copyright, and provided solely for personal, not commercial use. All property information should be verified by the recipient and none is guaranteed as accurate.`}{' '}
                </>
              )}

              {showPromotionLegal && (
                <>{`Eligibility may vary. Not all promotions may be combined.`} </>
              )}
            </Text>
            <Text tag="p" typography="$bodyMedium" display="inline" color={theme.contentPrimary}>
              CA: Opendoor Brokerage Inc. DRE #02061130
            </Text>
            <Text tag="p" typography="$bodyMedium" display="inline" color={theme.contentPrimary}>
              NY:{' '}
              <Link
                analyticsName="cosmos-landing-page-v2-footer-legal-link"
                aria-label="Licensed as Opendoor Brokerage LLC pdf link"
                href="https://s3.amazonaws.com/odfil.es/static/documents/Dan+NY+Notarized+Doc.pdf"
                hoverStyle={{ color: theme.contentPrimary }}
                color={theme.contentPrimary}
                textDecorationLine="underline"
              >
                Licensed as Opendoor Brokerage LLC
              </Link>
              ,{' '}
              <Link
                analyticsName="cosmos-landing-page-v2-footer-legal-link"
                aria-label="Fair Housing Notice pdf link"
                href="https://s3.amazonaws.com/odfil.es/static/documents/fair-housing-notice.pdf"
                hoverStyle={{ color: theme.contentPrimary }}
                color={theme.contentPrimary}
                textDecorationLine="underline"
              >
                Fair Housing Notice
              </Link>
            </Text>
            <Text tag="p" display="inline" typography="$bodyMedium" color={theme.contentPrimary}>
              TX:{' '}
              <Link
                analyticsName="cosmos-landing-page-v2-footer-legal-link"
                aria-label="TREC Information About Brokerage Services pdf link"
                href="https://buy.opendoor.com/legal/texas/trec/iabs.pdf"
                hoverStyle={{ color: theme.contentPrimary }}
                color={theme.contentPrimary}
                textDecorationLine="underline"
              >
                TREC Information About Brokerage Services
              </Link>
              ,{' '}
              <Link
                analyticsName="cosmos-landing-page-v2-footer-legal-link"
                aria-label="TREC Consumer Protection Notice pdf link"
                href="https://www.trec.texas.gov/forms/consumer-protection-notice"
                hoverStyle={{ color: theme.contentPrimary }}
                color={theme.contentPrimary}
                textDecorationLine="underline"
              >
                TREC Consumer Protection Notice
              </Link>
            </Text>
            <Text tag="p" display="inline" typography="$bodyMedium" color={theme.contentPrimary}>
              Opendoor Labs Inc. is working with{' '}
              <Link
                analyticsName="cosmos-landing-page-v2-footer-legal-link"
                aria-label="Accessible360 link"
                href="https://accessible360.com/"
                hoverStyle={{ color: theme.contentPrimary }}
                color={theme.contentPrimary}
                textDecorationLine="underline"
              >
                Accessible360
              </Link>{' '}
              to enhance the accessibility of our websites and mobile applications. We view
              accessibility as a continually ongoing effort. Please contact us at
              support@opendoor.com with any feedback or suggestions.
            </Text>
          </Box>

          {/* Copyright + year section */}
          <Box
            display="flex"
            marginTop="$40x"
            alignItems="center"
            gap="$8x"
            flexDirection="row"
            flexWrap="wrap"
            $largerThanSM={{ justifyContent: 'flex-start' }}
          >
            <Text tag="span" typography="$bodyMedium" color={theme.contentPrimary}>
              &copy; {new Date().getFullYear()} Opendoor | All rights reserved.
            </Text>
            <Box
              flexDirection="row"
              gap="$8x"
              alignItems="center"
              // @ts-expect-error theme.color is a Token.
              color={theme.contentPrimary}
            >
              <Image
                src="https://images.opendoor.com/source/s3/imgdrop-production/f2398a1e83a24962a9297d13f2ac6ea5.png?preset=square-2048"
                loading="lazy"
                alt="Equal Housing Opportunity"
                width={38}
                height={38}
              />
              <Link
                href="https://www.bbb.org/us/az/tempe/profile/real-estate-services/opendoor-1126-1000060421/#sealclick"
                analyticsName="cosmos-landing-page-v2-footer-bbb-link"
                aria-label="Better Business Bureau accredited since June 8th 2021 link"
                hoverStyle={{ color: theme.contentPrimary }}
                color={theme.contentPrimary}
                textDecorationLine="underline"
              >
                <Image
                  src="https://images.opendoor.com/source/s3/imgdrop-production/1cd65eee0b8345689ceff0292986f125.png?preset=square-2048"
                  loading="lazy"
                  alt="Better Business Bureau"
                  height={38}
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
